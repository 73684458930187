import React, { useState, useEffect, useRef } from "react"

export const recruitArea = [
  {
    value: 'hokkaido',
    label: '北海道',
  },
  {
    value: 'sendai',
    label: '仙台',
  },
  {
    value: 'tokyo',
    label: '東京',
  },
  {
    value: 'yokohama',
    label: '横浜',
  },
  {
    value: 'nagoya',
    label: '名古屋',
  },
  {
    value: 'kyoto',
    label: '京都',
  },
  {
    value: 'osaka',
    label: '大阪',
  },
  {
    value: 'kobe',
    label: '神戸',
  },
  {
    value: 'hiroshima',
    label: '広島',
  },
  {
    value: 'fukuoka',
    label: '福岡',
  },
]
